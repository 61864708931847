import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
import Container from '../../components/container.jsx';
import img1 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-16.jpg';
import img2 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-18.jpg';
import img3 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-10.jpg';
import img4 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-20.jpg';
import img5 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-6.jpg';
import img6 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-8.jpg';
import img7 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-24.jpg';
import img8 from '../../static/img/work/photogaphy/Analog Photos/analog-photo.jpg';
import img9 from '../../static/img/work/photogaphy/Analog Photos/analog-photo-2.jpg';
export const _frontmatter = {
  "key": 5,
  "title": "Lomography",
  "client": null,
  "type": "Photography",
  "date": "2017-2019",
  "description": "When I walk around I often see interesting compositions, I find it really interesting to document these. It just happend that I do not always had a good camera with me, which resulted in me having to take pictures with my phone. These pictures do have some qualitaitve disadvantagess, especially less control. But in another way it feels like the camera is reinterpreting the scene for me. Throughout the years I learnt how to use these in my advantage so I can get a new interpretation.",
  "image": "../../static/img/work/photogaphy/lo-fi/FJIMG_20180301_163157.jpg",
  "alt": "love timewarp",
  "length": 534,
  "rating": 4,
  "draft": true
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery width={50} mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/925de882808832394a7bf3eb517fbf6e/be684/IMG_7616.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.6598611678236%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcJWiIA//8QAGBABAAMBAAAAAAAAAAAAAAAAAQMQEQD/2gAIAQEAAQUCBeyiTI6//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAARACESH/2gAIAQEABj8CZjQ1/wD/xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQQTFR/9oACAEBAAE/IeJCm0Z3ezkdf//aAAwDAQACAAMAAAAQRB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAQITFRYXH/2gAIAQEAAT8Q0hw2CE9VreQAmfMbmv/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/925de882808832394a7bf3eb517fbf6e/3cea3/IMG_7616.webp 1000w", "/static/925de882808832394a7bf3eb517fbf6e/b7abf/IMG_7616.webp 2000w", "/static/925de882808832394a7bf3eb517fbf6e/cc8bc/IMG_7616.webp 4000w", "/static/925de882808832394a7bf3eb517fbf6e/c4d34/IMG_7616.webp 4898w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/925de882808832394a7bf3eb517fbf6e/971c4/IMG_7616.jpg 1000w", "/static/925de882808832394a7bf3eb517fbf6e/4aca8/IMG_7616.jpg 2000w", "/static/925de882808832394a7bf3eb517fbf6e/cc43b/IMG_7616.jpg 4000w", "/static/925de882808832394a7bf3eb517fbf6e/be684/IMG_7616.jpg 4898w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/925de882808832394a7bf3eb517fbf6e/cc43b/IMG_7616.jpg",
                "alt": "cinema screen at exposition",
                "title": "cinema screen at exposition",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a7c31a227d64a87e609218ddc9814d8a/49a69/FJIMG_20180329_180442.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZz0TNQqD//EABoQAAMBAAMAAAAAAAAAAAAAAAABAgMEISL/2gAIAQEAAQUCvkToaM6M/Ktumf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAQIWH/2gAIAQEABj8Cj0q//8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERIUExUaH/2gAIAQEAAT8hczPKE80hsPM3WNJX5G+j/9oADAMBAAIAAwAAABDk/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFB/9oACAEDAQE/EJpUf//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAgEBPxDUf//EABsQAQEAAgMBAAAAAAAAAAAAAAERACExQWFx/9oACAEBAAE/EH4SlKHDd4/gFsPmNe8uq6bGxm6b4AmDdEPc/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/a7c31a227d64a87e609218ddc9814d8a/3cea3/FJIMG_20180329_180442.webp 1000w", "/static/a7c31a227d64a87e609218ddc9814d8a/b7abf/FJIMG_20180329_180442.webp 2000w", "/static/a7c31a227d64a87e609218ddc9814d8a/cc8bc/FJIMG_20180329_180442.webp 4000w", "/static/a7c31a227d64a87e609218ddc9814d8a/ea2d6/FJIMG_20180329_180442.webp 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/a7c31a227d64a87e609218ddc9814d8a/971c4/FJIMG_20180329_180442.jpg 1000w", "/static/a7c31a227d64a87e609218ddc9814d8a/4aca8/FJIMG_20180329_180442.jpg 2000w", "/static/a7c31a227d64a87e609218ddc9814d8a/cc43b/FJIMG_20180329_180442.jpg 4000w", "/static/a7c31a227d64a87e609218ddc9814d8a/49a69/FJIMG_20180329_180442.jpg 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/a7c31a227d64a87e609218ddc9814d8a/cc43b/FJIMG_20180329_180442.jpg",
                "alt": "restaurant",
                "title": "restaurant",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1111px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/84ceb56c3f5d07f0aaea6d9abd97c6bd/98722/IMG_1235.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "174.25742574257427%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAjABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAQFBgMC/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQACA//aAAwDAQACEAMQAAABuOmW9G+iE6ZkWdRreZnk02f2RFWC/8QAHhAAAgICAgMAAAAAAAAAAAAAAQMCBCIxABEhMzT/2gAIAQEAAQUCgfEtWPYbTQVkNY2WcUrm9SZQW2oSyozqwOOlnX+ganv/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQESIP/aAAgBAwEBPwGrkQ8f/8QAGREAAgMBAAAAAAAAAAAAAAAAAAESIEFx/9oACAECAQE/AW9JHaf/xAAdEAACAgEFAAAAAAAAAAAAAAABEQACECEiQVFx/9oACAEBAAY/AspiO2sXUpQNcy4ACe2EuA2zT3P/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFhoRD/2gAIAQEAAT8huhF7YvEdCg6invI8xzk0lDpZzlG0WRdw0EHRCvmqJp1GN+J5fxyzn//aAAwDAQACAAMAAAAQiM6M8+//xAAZEQACAwEAAAAAAAAAAAAAAAAAARARITH/2gAIAQMBAT8QfGOmDo8LY4//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEQ/9oACAECAQE/EDIiNybxv//EACAQAQACAgEEAwAAAAAAAAAAAAEAESFRMUFhgbHB0eH/2gAIAQEAAT8QIWgIGiAOrKhWvtlqOWJzNQqgpQNO/giM2BR7XZ7hKhtl1DONSzUAGqOF+9SgAy3Qrjxx4ifoUwXS4IhwKHE74ad2JEIYY6ivcn9n/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/84ceb56c3f5d07f0aaea6d9abd97c6bd/3cea3/IMG_1235.webp 1000w", "/static/84ceb56c3f5d07f0aaea6d9abd97c6bd/a92b4/IMG_1235.webp 1111w"],
                "sizes": "(max-width: 1111px) 100vw, 1111px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/84ceb56c3f5d07f0aaea6d9abd97c6bd/971c4/IMG_1235.jpg 1000w", "/static/84ceb56c3f5d07f0aaea6d9abd97c6bd/98722/IMG_1235.jpg 1111w"],
                "sizes": "(max-width: 1111px) 100vw, 1111px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/84ceb56c3f5d07f0aaea6d9abd97c6bd/98722/IMG_1235.jpg",
                "alt": "School girl China",
                "title": "School girl China",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "3840px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e8ba83051f1e686fec3ef7897647160c/125ef/FJIMG_20180505_220247.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAME/9oADAMBAAIQAxAAAAEkTa6OIUNv/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxAh/9oACAEBAAEFAi/Hwy3P/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAIRA//aAAgBAwEBPwHNIQ//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BbhT/xAAXEAADAQAAAAAAAAAAAAAAAAAAARAh/9oACAEBAAY/Ajap/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERMUEhgf/aAAgBAQABPyFTsO4jGktDDc9Ds//aAAwDAQACAAMAAAAQx/8A/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EAyn/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EJAn/8QAHBABAQEAAQUAAAAAAAAAAAAAAREAITFBUWHB/9oACAEBAAE/EJiK44BvA8jexkZXvkRenwzNvnf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/e8ba83051f1e686fec3ef7897647160c/3cea3/FJIMG_20180505_220247.webp 1000w", "/static/e8ba83051f1e686fec3ef7897647160c/b7abf/FJIMG_20180505_220247.webp 2000w", "/static/e8ba83051f1e686fec3ef7897647160c/98288/FJIMG_20180505_220247.webp 3840w"],
                "sizes": "(max-width: 3840px) 100vw, 3840px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/e8ba83051f1e686fec3ef7897647160c/971c4/FJIMG_20180505_220247.jpg 1000w", "/static/e8ba83051f1e686fec3ef7897647160c/4aca8/FJIMG_20180505_220247.jpg 2000w", "/static/e8ba83051f1e686fec3ef7897647160c/125ef/FJIMG_20180505_220247.jpg 3840w"],
                "sizes": "(max-width: 3840px) 100vw, 3840px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e8ba83051f1e686fec3ef7897647160c/125ef/FJIMG_20180505_220247.jpg",
                "alt": "Develish at concert",
                "title": "Develish at concert",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bf4e68ed23e5afa4a3afd0d746922f4f/49a69/FJIMG_20171225_170922.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAU3M2ZcIGb//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIh/9oACAEBAAEFAkHvHQKYVsyGxiJ//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AZGP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BWf/EABoQAAICAwAAAAAAAAAAAAAAAAABAiEQETH/2gAIAQEABj8CKNOSRSO4/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERITFRgf/aAAgBAQABPyFWB0EllsrjY3B6R5xXFQ9P/9oADAMBAAIAAwAAABAUP//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxBXY//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QE4W3/8QAGxABAAICAwAAAAAAAAAAAAAAAQARMUEhUZH/2gAIAQEAAT8Q7xxHK4YCEgrkNkbFCbh4tddIy2fZ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/bf4e68ed23e5afa4a3afd0d746922f4f/3cea3/FJIMG_20171225_170922.webp 1000w", "/static/bf4e68ed23e5afa4a3afd0d746922f4f/b7abf/FJIMG_20171225_170922.webp 2000w", "/static/bf4e68ed23e5afa4a3afd0d746922f4f/cc8bc/FJIMG_20171225_170922.webp 4000w", "/static/bf4e68ed23e5afa4a3afd0d746922f4f/ea2d6/FJIMG_20171225_170922.webp 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/bf4e68ed23e5afa4a3afd0d746922f4f/971c4/FJIMG_20171225_170922.jpg 1000w", "/static/bf4e68ed23e5afa4a3afd0d746922f4f/4aca8/FJIMG_20171225_170922.jpg 2000w", "/static/bf4e68ed23e5afa4a3afd0d746922f4f/cc43b/FJIMG_20171225_170922.jpg 4000w", "/static/bf4e68ed23e5afa4a3afd0d746922f4f/49a69/FJIMG_20171225_170922.jpg 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/bf4e68ed23e5afa4a3afd0d746922f4f/cc43b/FJIMG_20171225_170922.jpg",
                "alt": "traveling",
                "title": "traveling",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cfe3af9db4e4a45a1953fd9f77660978/49a69/FJIMG_20180301_163157.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFFRTj2NopD/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDBAASE0H/2gAIAQEAAQUC9rR7iXkjDEuBEktl2//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAARAQIQIf/aAAgBAQAGPwI6KaYooNH/xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMUFR/9oACAEBAAE/IUFVSpLpky3Lg3EhNOelITUP/9oADAMBAAIAAwAAABB7L//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMVHwQf/aAAgBAQABPxDCpunkgYonLblE4DTPZFRR0gzULfp7gC3KM93P/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/cfe3af9db4e4a45a1953fd9f77660978/3cea3/FJIMG_20180301_163157.webp 1000w", "/static/cfe3af9db4e4a45a1953fd9f77660978/b7abf/FJIMG_20180301_163157.webp 2000w", "/static/cfe3af9db4e4a45a1953fd9f77660978/cc8bc/FJIMG_20180301_163157.webp 4000w", "/static/cfe3af9db4e4a45a1953fd9f77660978/ea2d6/FJIMG_20180301_163157.webp 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/cfe3af9db4e4a45a1953fd9f77660978/971c4/FJIMG_20180301_163157.jpg 1000w", "/static/cfe3af9db4e4a45a1953fd9f77660978/4aca8/FJIMG_20180301_163157.jpg 2000w", "/static/cfe3af9db4e4a45a1953fd9f77660978/cc43b/FJIMG_20180301_163157.jpg 4000w", "/static/cfe3af9db4e4a45a1953fd9f77660978/49a69/FJIMG_20180301_163157.jpg 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/cfe3af9db4e4a45a1953fd9f77660978/cc43b/FJIMG_20180301_163157.jpg",
                "alt": "staring out the window",
                "title": "staring out the window",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "3840px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/05488d5a394e3334fbc9d30e9092ca48/125ef/FJIMG_20180505_222228.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABmicYkXl4f//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREBL/2gAIAQEAAQUCuoQK6Ycnoxzz/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AdWf/8QAGBAAAgMAAAAAAAAAAAAAAAAAARARIzH/2gAIAQEABj8CrMPX/8QAGhAAAgIDAAAAAAAAAAAAAAAAABEhMQFBUf/aAAgBAQABPyHhnK2O1QxAM0ln/9oADAMBAAIAAwAAABBH/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjX//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EJ2Kf//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFBMVFhcf/aAAgBAQABPxBU38BYp0/Y3K6pPCK0VUsi8Q2Z/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/05488d5a394e3334fbc9d30e9092ca48/3cea3/FJIMG_20180505_222228.webp 1000w", "/static/05488d5a394e3334fbc9d30e9092ca48/b7abf/FJIMG_20180505_222228.webp 2000w", "/static/05488d5a394e3334fbc9d30e9092ca48/98288/FJIMG_20180505_222228.webp 3840w"],
                "sizes": "(max-width: 3840px) 100vw, 3840px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/05488d5a394e3334fbc9d30e9092ca48/971c4/FJIMG_20180505_222228.jpg 1000w", "/static/05488d5a394e3334fbc9d30e9092ca48/4aca8/FJIMG_20180505_222228.jpg 2000w", "/static/05488d5a394e3334fbc9d30e9092ca48/125ef/FJIMG_20180505_222228.jpg 3840w"],
                "sizes": "(max-width: 3840px) 100vw, 3840px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/05488d5a394e3334fbc9d30e9092ca48/125ef/FJIMG_20180505_222228.jpg",
                "alt": "concert",
                "title": "concert",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a00e531b3e39ec8d4b5f3036c707476c/49a69/FJIMG_20171225_170930.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAZb0t2ORga//xAAbEAABBAMAAAAAAAAAAAAAAAABAgMRIQASMv/aAAgBAQABBQJjpWFNoMHaQXb/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAABESAh/9oACAEBAAY/AiKYj//EABsQAQACAgMAAAAAAAAAAAAAAAEAMRAhQVGB/9oACAEBAAE/Iboh3xvfhqNnMKknbP/aAAwDAQACAAMAAAAQ/wAP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxCVk0//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/ELEV4f/EAB4QAAIBAwUAAAAAAAAAAAAAAAABESExgUFRYXGR/9oACAEBAAE/EI40MbOCqh07JibJBtT1bVCTLlbL8P/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/a00e531b3e39ec8d4b5f3036c707476c/3cea3/FJIMG_20171225_170930.webp 1000w", "/static/a00e531b3e39ec8d4b5f3036c707476c/b7abf/FJIMG_20171225_170930.webp 2000w", "/static/a00e531b3e39ec8d4b5f3036c707476c/cc8bc/FJIMG_20171225_170930.webp 4000w", "/static/a00e531b3e39ec8d4b5f3036c707476c/ea2d6/FJIMG_20171225_170930.webp 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/a00e531b3e39ec8d4b5f3036c707476c/971c4/FJIMG_20171225_170930.jpg 1000w", "/static/a00e531b3e39ec8d4b5f3036c707476c/4aca8/FJIMG_20171225_170930.jpg 2000w", "/static/a00e531b3e39ec8d4b5f3036c707476c/cc43b/FJIMG_20171225_170930.jpg 4000w", "/static/a00e531b3e39ec8d4b5f3036c707476c/49a69/FJIMG_20171225_170930.jpg 4160w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/a00e531b3e39ec8d4b5f3036c707476c/cc43b/FJIMG_20171225_170930.jpg",
                "alt": "traveling",
                "title": "traveling",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "3431px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d6cdf41cfe28f96164187ec840e15f27/d6295/FJIMG_20180805_171648.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.92218012241329%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAUrsmcnhMr//xAAYEAADAQEAAAAAAAAAAAAAAAABAgMSEP/aAAgBAQABBQKZTQaQsxGu/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERMSAiUv/aAAgBAQAGPwLahcDisP/EABoQAAICAwAAAAAAAAAAAAAAAAABECERQWH/2gAIAQEAAT8hEFg0ntR2BRkUf//aAAwDAQACAAMAAAAQTA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxAj/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFhgaH/2gAIAQEAAT8QdQOfsUFDyDNd6el5BV1ic2C12f/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/d6cdf41cfe28f96164187ec840e15f27/3cea3/FJIMG_20180805_171648.webp 1000w", "/static/d6cdf41cfe28f96164187ec840e15f27/b7abf/FJIMG_20180805_171648.webp 2000w", "/static/d6cdf41cfe28f96164187ec840e15f27/b94ab/FJIMG_20180805_171648.webp 3431w"],
                "sizes": "(max-width: 3431px) 100vw, 3431px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/d6cdf41cfe28f96164187ec840e15f27/971c4/FJIMG_20180805_171648.jpg 1000w", "/static/d6cdf41cfe28f96164187ec840e15f27/4aca8/FJIMG_20180805_171648.jpg 2000w", "/static/d6cdf41cfe28f96164187ec840e15f27/d6295/FJIMG_20180805_171648.jpg 3431w"],
                "sizes": "(max-width: 3431px) 100vw, 3431px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d6cdf41cfe28f96164187ec840e15f27/d6295/FJIMG_20180805_171648.jpg",
                "alt": "in train",
                "title": "in train",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cf9467999ea678bb6cd542ebd2a73f4b/be684/IMG_7596.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.6598611678236%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHhLlag/8QAFxAAAwEAAAAAAAAAAAAAAAAAARARIP/aAAgBAQABBQKI4//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABcQAQEBAQAAAAAAAAAAAAAAAAERABD/2gAIAQEAAT8hYmmMctO//9oADAMBAAIAAwAAABCTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExQf/aAAgBAQABPxBFb2aamOcq4oD5yXsVetz/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/cf9467999ea678bb6cd542ebd2a73f4b/3cea3/IMG_7596.webp 1000w", "/static/cf9467999ea678bb6cd542ebd2a73f4b/b7abf/IMG_7596.webp 2000w", "/static/cf9467999ea678bb6cd542ebd2a73f4b/cc8bc/IMG_7596.webp 4000w", "/static/cf9467999ea678bb6cd542ebd2a73f4b/c4d34/IMG_7596.webp 4898w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/cf9467999ea678bb6cd542ebd2a73f4b/971c4/IMG_7596.jpg 1000w", "/static/cf9467999ea678bb6cd542ebd2a73f4b/4aca8/IMG_7596.jpg 2000w", "/static/cf9467999ea678bb6cd542ebd2a73f4b/cc43b/IMG_7596.jpg 4000w", "/static/cf9467999ea678bb6cd542ebd2a73f4b/be684/IMG_7596.jpg 4898w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/cf9467999ea678bb6cd542ebd2a73f4b/cc43b/IMG_7596.jpg",
                "alt": "ghosts",
                "title": "ghosts",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    </Gallery>
    <Container mdxType="Container">
      <h2>{`Intentionally Uncontrolled`}</h2>
      <p>{`This experimentational and inventive way of thinking became something that influenced my image making strongly. Even when I have more control.`}</p>
    </Container>
    <Gallery width={35} mdxType="Gallery">
      <img src={img3} alt="piece of the film" />
      <img src={img4} alt="piece of the film" />
    </Gallery>
    <Gallery width={33} mdxType="Gallery">
    <img src={img1} alt="piece of the film" />
    <img src={img2} alt="piece of the film" />
    <img src={img5} alt="piece of the film" />
    <img src={img6} alt="piece of the film" />
    <img src={img7} alt="piece of the film" />
    <img src={img8} alt="piece of the film" />
    <img src={img9} alt="piece of the film" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      